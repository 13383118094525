/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EducationEnum } from './educationEnum';
import { ExtendedGenderEnum } from './extendedGenderEnum';
import { LengthOfPracticeEnum } from './lengthOfPracticeEnum';
import { RegionEnum } from './regionEnum';

export interface ReadParentAdditionalInformation { 
    contractConsent?: boolean;
    lengthOfPractice?: LengthOfPracticeEnum;
    region?: RegionEnum;
    education?: EducationEnum;
    extendedGender?: ExtendedGenderEnum;
}