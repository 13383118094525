<div class="modal-header">
  <h2 class="modal-title">{{ data.title }}</h2>
  <button (click)="activeModal.dismiss(null)" aria-label="Close" class="btn-close"><span aria-hidden="true">×</span></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="!inputValid">
    <ngb-alert type="danger" [dismissible]="false">
      {{ data.validationMessage }}
    </ngb-alert>
  </ng-container>
  <ng-container *ngIf="data.alertType !== null && data.popUpType !== popUpType.PROMPT">
    <ngb-alert type="{{ data.alertType }}" [dismissible]="false">
      <p [innerHTML]="data.text"></p>
    </ngb-alert>
  </ng-container>
  <ng-container *ngIf="data.alertType === null && data.popUpType !== popUpType.PROMPT">
    <p [innerHTML]="data.text"></p>
  </ng-container>
  <ng-container *ngIf="data.popUpType === popUpType.PROMPT">
    <div class="from-group">
      <label for="inputValue">{{ data.text }}</label>
      <div class="input-group">
        <input
          id="inputValue"
          class="form-control"
          [placeholder]="data.placeholder"
          name="name"
          [(ngModel)]="inputValue"
          (input)="inputValid = true"
          required
        />
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button *ngIf="data.popUpType === popUpType.PROMPT" class="btn btn-primary" (click)="activeModal.close(false)" tabindex="0">
    {{ data.cancelText }}
  </button>
  <button *ngIf="data.popUpType === popUpType.PROMPT" class="btn btn-primary" (click)="okPromptButton()" tabindex="0">
    {{ data.okText }}
  </button>
  <button *ngIf="data.popUpType === popUpType.ALERT" class="btn btn-primary" (click)="activeModal.close(true)" tabindex="0">
    {{ data.okText }}
  </button>
  <button *ngIf="data.popUpType === popUpType.CONFIRM" class="btn {{ data.noButtonClass }}" (click)="activeModal.close(false)" tabindex="0">
    {{ data.noText }}
  </button>
  <button *ngIf="data.popUpType === popUpType.CONFIRM" class="btn {{ data.yesButtonClass }}" (click)="activeModal.close(true)" tabindex="0">
    {{ data.yesText }}
  </button>
</div>
