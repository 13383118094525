import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouterLinkFactory } from '@app/core/router/router-link.factory';
import { LicenseService } from '@app/core/services/license.service';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-license',
  templateUrl: './edit-license.component.html',
  styleUrls: ['./edit-license.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EditLicenseComponent {
  activationCode = '';

  public constructor(
    public activeModal: NgbActiveModal,
    public licenseService: LicenseService,
    private toastr: ToastrService,
    private router: Router,
    private routerLinkFactory: RouterLinkFactory
  ) {}

  togglePremium() {
    this.licenseService.premium$.next(!this.licenseService.premium$.value);
  }

  public onSubmit(): void {
    this.licenseService.applyActivationCode(this.activationCode).subscribe({
      next: () => {
        this.activeModal.close();
        this.toastr.success('Aktivační kód úspěšně uplatněn.');
        this.router.navigate(this.routerLinkFactory.homepage());
      },
      error: () => {
        this.toastr.error('Aktivační kód je nevalidní nebo už byl použit.');
      }
    });
  }
}
