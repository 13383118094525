import { Injectable } from '@angular/core';
import { Child } from '@parents-api';

@Injectable({
  providedIn: 'root'
})
export class RouterLinkFactory {
  /**
   * Navigate application to landing page.
   *
   * Page not required login.
   */
  public landingPage(): string[] {
    return ['/public'];
  }

  /**
   * Navigate application to homepage.
   *
   * Homepage is main page (component), which is rendered after login.
   * It is always safe to redirect to homepage.
   */
  public homepage(): string[] {
    return this.about();
  }

  /**
   * Navigate application to login.
   */
  public login(): string[] {
    return ['/login'];
  }

  /**
   * Navigate application to result recommendation by child object.
   */
  public recommendation(child?: Child): string[] {
    if (child) {
      return ['/result', child.uuid, 'recommendation'];
    } else {
      return ['/result', 'recommendation']; // TODO: (js) these routes didn't work even before
    }
  }

  /**
   * Navigate application to result recommendation by child uuid.
   */
  public recommendationByChildUuid(childUuid: string): string[] {
    return ['/result', childUuid, 'recommendation'];
  }

  /**
   * Navigate application to result.
   */
  public result(child?: Child): string[] {
    if (child) {
      return ['/result', child.uuid];
    } else {
      return ['/result']; // TODO: (js) these routes didn't work even before
    }
  }

  /**
   * Navigate application to forgotten password page, where user can reset his password.
   */
  public forgottenPassword(): string[] {
    return ['/login', 'forgotten-password'];
  }

  /**
   * Navigate application to registration page.
   */
  public registration(): string[] {
    return ['/registration'];
  }

  /**
   * Navigate application to dashboard.
   *
   */
  public dashboard(): string[] {
    return ['/dashboard'];
  }

  /**
   * Navigate application to change password page.
   *
   */
  public changePassword(): string[] {
    return ['/user', 'change-password'];
  }

  /**
   * Navigate application to licenses page.
   *
   */
  public licenses(): string[] {
    return ['/user', 'licenses'];
  }

  /**
   * Navigate application to sync.
   */
  public sync(): string[] {
    return ['/sync'];
  }

  /**
   * Navigate application to show page with successful activation of an account.
   */
  public activatedPage(): string[] {
    return ['/registration', 'activate'];
  }

  /**
   * Navigate application to show page with successful registration of an account.
   */
  public successPage(): string[] {
    return ['/registration', 'success'];
  }

  /**
   * Navigate application to fill questionnaire for a child.
   */
  public fillQuestionnaire(child?: Child): string[] {
    if (child) {
      return ['/questionnaire', child.uuid, 'fill'];
    } else {
      return ['/questionnaire', 'fill'];
    }
  }

  /**
   * Navigate application to edit user data page.
   */
  public editUser(): string[] {
    return ['/user', 'edit-user'];
  }

  /**
   * Recommendations - no user
   */
  public recommendationNouser(): string[] {
    return ['/info', 'recommendation-nouser'];
  }

  /**
   * Info page - Why to use the tool.
   */
  public whyIsophi(): string[] {
    return ['/info', 'why-isophi'];
  }

  /**
   * Info page - contact info.
   */
  public contact(): string[] {
    return ['/info', 'contact'];
  }

  /**
   * Info page - about info.
   */
  public about(): string[] {
    return ['/info', 'about'];
  }

  /**
   * Info page - What is self-evaluation about info
   */
  public selfEvaluationAbout(): string[] {
    return ['/info', 'self-evaluation-about'];
  }

  /**
   * Info page - Certificate of Participation
   */
  public participation(): string[] {
    return ['/info', 'participation-certificate'];
  }

  /**
   * Info page - Bonus: E-Learning
   */
  public bonusELearning(): string[] {
    return ['/info', 'bonus-e-learning'];
  }
}
