import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouterLinkFactory } from '@app/core/router/router-link.factory';
import { ChildService } from '@app/core/services/child.service';
import { toSnakecaseFun } from '@app/core/utils/object.utils';
import { environment } from '@env/environment';
import { Child } from '@parents-api';
import { User } from 'isophi-core';
import { Logger } from 'isophi-core';
import { NgxSpinnerService } from 'ngx-spinner';

/**
 * Service to manage communication with other iSophi systems,
 * when running in iframe.
 *
 * iSophi message format:
 * {'key': 'msg_keyword', 'data': 'any_data_for_msg_type', 'provider': 'iSophi', 'isophi_app': 'app-keyword'}
 */
@Injectable({
  providedIn: 'root'
})
export class MessageService {
  public constructor(
    private router: Router,
    public routerLinkFactory: RouterLinkFactory,
    private childService: ChildService,
    private spinner: NgxSpinnerService
  ) {}

  /**
   * Method to receive messages from other app.
   */
  public messageReceiver = (event) => {
    let messageData = null;

    try {
      messageData = JSON.parse(event.data);
    } catch (e) {
      // do not process message in not iSophi format.
      return;
    }

    if (
      typeof messageData !== 'object' ||
      typeof messageData.key === 'undefined' ||
      typeof messageData.data === 'undefined' ||
      typeof messageData.provider === 'undefined' ||
      typeof messageData.isophi_app === 'undefined'
    ) {
      // Process message only from iSophi apps in iSophi format
      return;
    }

    if (typeof messageData.provider !== 'string' && messageData.provider !== 'iSophi') {
      // Process message only from iSophi apps
      return;
    }

    const key = messageData.key;
    const data = messageData.data;

    if (key === 'navigate') {
      this.receiveNavigateMessage(data);
    } else {
      throw Error(`Unknown message type with key: ${key}`);
    }
  };

  /**
   * Init listener for communication from other app.
   */
  public initMessageListener() {
    Logger.debug('MessageService :: message listener initialized');
    window.addEventListener('message', this.messageReceiver, false);
  }

  /**
   * Send logged user data to another app.
   *
   * @param window
   * @param user
   */
  public sendLoggedUser(window: any, user: User) {
    if (!window || !user) {
      return;
    }

    window.postMessage(
      JSON.stringify({
        key: 'logged_user',
        data: {
          user: user.serialize(),
          type: 'parent'
        },
        isophi_app: environment.common.appKeyword,
        provider: 'iSophi'
      }),
      '*'
    );
  }

  /**
   * Send child data to another app.
   *
   * @param window
   * @param child
   */
  public sendChild(window: any, child: Child) {
    if (!window || !child) {
      return;
    }

    window.postMessage(
      JSON.stringify({
        key: 'child',
        data: child ? toSnakecaseFun(child) : null,
        isophi_app: environment.common.appKeyword,
        provider: 'iSophi'
      }),
      '*'
    );
  }

  /**
   * Send security token to another app.
   *
   * @param window
   * @param securityToken
   */
  public sendSecurityToken(window: any, securityToken: string) {
    if (!window || !securityToken) {
      return;
    }

    window.postMessage(
      JSON.stringify({
        key: 'security_token',
        data: securityToken,
        isophi_app: environment.common.appKeyword,
        provider: 'iSophi'
      }),
      '*'
    );
  }

  /**
   * Send security token to another app.
   *
   * @param window
   * @param questionnaireType
   */
  public sendQuestionnaireType(window: any, questionnaireType: string) {
    if (!window || !questionnaireType) {
      return;
    }

    window.postMessage(
      JSON.stringify({
        key: 'questionnaire_type',
        data: questionnaireType,
        isophi_app: environment.common.appKeyword,
        provider: 'iSophi'
      }),
      '*'
    );
  }

  /**
   * Send security token to another app.
   *
   * @param window
   * @param lang i18n language
   */
  public sendLanguage(window: any, lang: string) {
    if (!window || !lang) {
      return;
    }

    window.postMessage(
      JSON.stringify({
        key: 'language',
        data: lang,
        isophi_app: environment.common.appKeyword,
        provider: 'iSophi'
      }),
      '*'
    );
  }

  /**
   * Method to receive navigation request from other app.
   *
   * @param data
   */
  protected receiveNavigateMessage(data) {
    if (data.destination === 'show_questionnaire_result') {
      const child = this.childService.getSelectedChildValue();
      if (child) {
        this.childService.getLastResults(child.uuid, true);
        this.router.navigate(this.routerLinkFactory.result(child)).then(() => this.spinner.show('results'));
      } else {
        this.router.navigate(this.routerLinkFactory.homepage());
      }
    }
  }
}
