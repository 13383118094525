<ng-container *transloco="let t">
  <div
    class="app-header header sticky"
    *ngIf="
      loggedUser && {
        children: children$ | async,
        licenses: licenses$ | async
      } as vm
    "
  >
    <div class="container-fluid main-container">
      <div class="d-flex d-flex align-items-baseline">
        <div class="app-sidebar__toggle" appSidemenuToggle>
          <a class="open-toggle" href="javascript:;">
            <i class="fe fe-menu"></i>
          </a>
          <a class="close-toggle" href="javascript:;">
            <i class="fe fe-menu"></i>
          </a>
        </div>

        <h1 class="mb-0">
          {{ title$ | async }}
        </h1>

        <div class="d-flex order-lg-2 my-auto ms-auto">
          <div class="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
            <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
              <div class="d-flex ms-auto">
                <!-- <app-language-picker></app-language-picker> -->

                <button (click)="startQuestionnaire()" class="add-task btn btn-primary mr-4">
                  <img class="mr-2" src="assets/img/iconsDefault/add-task.svg" alt="Add task" />
                  {{ t('child/addChild') }}
                </button>

                <div class="dropdown header-flags">
                  <img src="assets/img/iconsDefault/account-circle.svg" alt="Account circle" />
                  <button
                    type="button"
                    id="manager-menu-profile"
                    class="btn nav-link py-0 pl-2 pr-3 border-0"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ username }}
                  </button>
                  <img src="assets/img/arrow-down.svg" alt="Arrow down" />
                  <div class="dropdown-menu dropdown-menu-end" aria-labelledby="manager-menu-profile">
                    <div class="dropdown-item disabled">{{ username }}</div>
                    <hr />
                    <a class="dropdown-item" data-cy-test="Změna hesla" [routerLink]="routerLinkFactory.changePassword()">{{
                      t('user/passwordChange')
                    }}</a>
                    <button class="dropdown-item" (click)="onLogout()">{{ t('shared/logOut') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
