import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLinkFactory } from '@app/core/router/router-link.factory';
import { Child, LastResults } from '@parents-api';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent {
  @Input() results: LastResults;

  @Input() selectedChild: Child;

  @Input() children: Child[];

  constructor(public routerLinkFactory: RouterLinkFactory) {}

  get hasResults(): boolean {
    return this.isChildSelected && this.hasSelectedChildResults;
  }

  get isChildSelected(): boolean {
    return this.selectedChild !== null;
  }

  get hasSelectedChildResults(): boolean {
    return true; // return hasAnyResult(this.results);
  }
}
