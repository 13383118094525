import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@app/core/services/auth-guard.service';
import { environment } from '@env/environment';

import { ActivationGuard } from './core/guards/activation-guard.guard';
import { DoLogoutGuard } from './core/guards/do-logout.guard';
import { LayoutComponent } from './shared/pages/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [ActivationGuard],
    pathMatch: 'full',
    children: []
  },
  {
    path: 'login',
    loadChildren: () => import('@app/modules/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'registration',
    component: LayoutComponent,
    loadChildren: () => import('@app/modules/registration/registration.module').then((m) => m.RegistrationModule),
    canLoad: [DoLogoutGuard]
  },
  {
    path: 'questionnaire/:childUuid',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@app/modules/questionnaire/questionnaire.module').then((m) => m.QuestionnaireModule)
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'user',
        loadChildren: () => import('@app/modules/user/user.module').then((m) => m.UserModule)
      },
      {
        path: 'result/:childUuid',
        loadChildren: () => import('@app/modules/result/result.module').then((m) => m.ResultModule)
      },
      {
        path: 'info',
        loadChildren: () => import('@app/modules/info/info.module').then((m) => m.InfoModule)
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: !environment.production && false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 75]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
