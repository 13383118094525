import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { environment } from '@env/environment';
import { ApiModule as ConfigApiModule, BASE_PATH as BASE_PATH_CONFIG, Configuration as ConfigConfig } from '@parents-api';

export const basePathFactory = (): string => {
  const baseUrl = environment.api;
  return baseUrl;
};

export const configFactory = (): ConfigConfig => new ConfigConfig({ apiKeys: {} });

@NgModule({
  imports: [ConfigApiModule],
  declarations: [],
  exports: [ConfigApiModule]
})
export class ApiModule {
  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }

  public static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        { provide: ConfigConfig, useFactory: configFactory },
        { provide: BASE_PATH_CONFIG, useFactory: basePathFactory }
      ]
    };
  }
}
