import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-gdpr-info',
  templateUrl: './gdpr-info.component.html',
  styleUrls: ['./gdpr-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GdprInfoComponent {
  public gdprContractUrl = `${environment.contractServer}/media/contracts/podminky-zpracovani-osobnich-udaju-v1.0.pdf`;

  public constructor() {}
}
