import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular-ivy';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { ApiModule } from './api';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ApiInterceptor } from './core/guards/api.interceptor';
import { InitService } from './core/services/init.service';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';

registerLocaleData(localeCs, 'cs');

export const initApplication = (initService: InitService) => () => initService.initApplication();

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApiModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    TranslocoRootModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    NgxSpinnerModule,
    HttpClientModule,
    FormsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga_measurement_id),
    NgxGoogleAnalyticsRouterModule,
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    { provide: LOCALE_ID, useValue: 'cs' },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [InitService, Sentry.TraceService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
