import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BuyLicenseComponent } from '../buy-license/buy-license.component';

@Component({
  selector: 'app-previewable',
  templateUrl: './previewable.component.html',
  styleUrls: ['./previewable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewableComponent {
  @Input() previewTitle = '';

  @Input() previewImage: string;

  @Input() locked = false;

  constructor(private modal: NgbModal) {}

  public openBuyLicense(event) {
    this.modal.open(BuyLicenseComponent);
    event.preventDefault();
  }
}
