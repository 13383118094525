import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpType } from 'isophi-core';

@Component({
  selector: 'app-pop-up-dialog',
  templateUrl: './pop-up-dialog.component.html',
  styleUrls: ['./pop-up-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PopUpDialogComponent {
  public popUpType: any;

  public inputValue = '';

  public inputValid = true;

  public data: any;

  public constructor(public activeModal: NgbActiveModal) {
    this.popUpType = PopUpType;

    if (this.data && Object.prototype.hasOwnProperty.call(this.data, 'defaultValue')) {
      this.inputValue = this.data.defaultValue;
    }
  }

  public okPromptButton(): void {
    const validationRegex = this.data.validationRegex;

    if (validationRegex !== null && this.inputValue.match(validationRegex) === null) {
      this.inputValid = false;
      return;
    }

    this.activeModal.close(this.inputValue);
  }
}
