import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  Child,
  ChildrenService,
  Contract,
  CopyChildProfile,
  CreateChild,
  Kindergarten,
  LastResults,
  ParentAdditionalInfoService
} from '@parents-api';
import { BehaviorSubject, concatWith, merge, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { contractVersion } from '../utils/contract.utils';

@Injectable({
  providedIn: 'root'
})
export class ChildService {
  public wasLanguageChosen = false;

  public additionalInfo$: Observable<boolean>;

  public selectedChildSubject$ = new BehaviorSubject<Child | null>(null);

  private childrenReload$ = new Subject<void>();

  private children$: Observable<Child[]>;

  private lastResults$: { [uuid: string]: Observable<LastResults> } = {};

  public constructor(
    private childrenApiService: ChildrenService,
    private i18n: TranslocoService,
    private parentAdditionalInfoService: ParentAdditionalInfoService
  ) {
    this.additionalInfo$ = this.checkAdditionalInfo().pipe(shareReplay(1));
  }

  public copyProfileFromKindergarten(copyChildProfile: CopyChildProfile): Observable<Child> {
    return this.childrenApiService.copyProfileFromKindergarten(copyChildProfile);
  }

  public createChild(child: Child, contract: Contract, consent: boolean) {
    const { uuid, firstName, lastName, birthDate, gender } = child;
    const createChild: CreateChild = {
      uuid,
      firstName,
      lastName,
      birthDate,
      gender,
      revision: 0,
      childManualRegistrationConsent: consent,
      childManualRegistrationVersion: contractVersion(contract)
    };
    return this.childrenApiService.createChild(createChild);
  }

  public deleteChild(uuid: string): Observable<void> {
    return this.childrenApiService.deleteChild(uuid);
  }

  public getChild(uuid: string): Observable<Child> {
    return this.childrenApiService.getChild(uuid);
  }

  public reloadChildren() {
    this.childrenReload$.next();
  }

  public clear() {
    this.wasLanguageChosen = false;
    this.children$ = null;
    this.selectedChildSubject$.next(null);
    this.lastResults$ = {};
  }

  public getChildren(reload = false): Observable<Child[]> {
    if (reload) {
      this.reloadChildren();
    }
    if (!this.children$) {
      // emit once on the first subscribe and then whenever childrenReload$ emits
      this.children$ = merge(of(0), this.childrenReload$).pipe(
        switchMap(() => this.childrenApiService.getChildren().pipe(map((response) => response.results))),
        shareReplay(1),
        tap((children) => {
          if (children.length > 0) {
            // Selecting automatically the last "child"
            // children.sort((a, b) => a.title.rendered.localeCompare(b.title.rendered));
            children.sort((a, b) => a.birthDate.localeCompare(b.birthDate));
            this.setSelectedChild(children[children.length - 1]);
          }
        }),
        catchError((error) => {
          console.log(error);
          throw new Error('Unable to download children data.');
        })
      );
    }
    return this.children$;
  }

  checkAdditionalInfo(): Observable<boolean> {
    return this.parentAdditionalInfoService.parentAdditionalInfoCheckAdditionalInfoRetrieve().pipe(shareReplay(1));
  }

  /**
   * Get kindergarten data by child-kindergarten copy token.
   *
   * @param code
   */
  public getKindergartenByChildCode(code: string): Observable<Kindergarten> {
    return this.childrenApiService.getKindergartenByCopyToken(code);
  }

  public updateChild(child: Child): Observable<Child> {
    return this.childrenApiService.updateChild(child, child.uuid);
  }

  public getLastResults(childUuid: string, reload = false): Observable<LastResults> {
    if (reload) {
      this.lastResults$[childUuid] = null;
    }
    if (!this.lastResults$[childUuid]) {
      this.lastResults$[childUuid] = this.childrenApiService.getLastResults(childUuid).pipe(shareReplay(1));
    }
    return this.lastResults$[childUuid];
  }

  // ##########################################################################
  //  End of API Service wrappers
  // ##########################################################################

  public addChildByCode(
    code: string,
    childCodeRegistrationContract: Contract,
    childCodeRegistrationConsent: boolean,
    userKindergartenSharingContract: Contract,
    kindergarten: Kindergarten
  ): Observable<any> {
    const data: CopyChildProfile = {
      code,
      childCodeRegistrationConsent,
      childCodeRegistrationVersion: contractVersion(childCodeRegistrationContract),
      userKindergartenSharingConsent: true, // Consent granted by submitting child form.
      userKindergartenSharingVersion: contractVersion(userKindergartenSharingContract),
      userKindergartenSharingKindergartenUuid: kindergarten.uuid
    };
    return this.copyProfileFromKindergarten(data);
  }

  /**
   * It map child API errors from code into texts (displayable to user).
   *
   * @param apiErrors   Error array from server.
   * @param appErrors   Array to put in error texts.
   */
  public mapChildApiErrors(apiErrors: Array<{ code: number }>, appErrors: Array<string>): void {
    for (const errorItem of apiErrors) {
      if (errorItem.code === 2502) {
        appErrors.push('Vámi zadaný kód je neplatný.');
      } else if (errorItem.code === 2503) {
        appErrors.push('Vámi zadaný kód již vypršel.');
      } else if (errorItem.code === 1501) {
        appErrors.push('Dítě bylo již přidáno.');
      } else if (errorItem.code === 2002) {
        appErrors.push('Musíte zadat kód dítěte.');
      } else {
        appErrors.push('Vyskytla se neznámá chyba.');
      }
    }
  }

  public setSelectedChild(child: Child) {
    if (this.selectedChildSubject$.value !== child) {
      this.selectedChildSubject$.next(child);

      // Call lang switch based on "last name" condition
      if (child?.firstName) this.setLangBasedOnChild(child);
    }
  }

  public setSelectedChildByUuid(uuid: string) {
    if (this.selectedChildSubject$.value?.uuid !== uuid) {
      (uuid ? this.getChildren().pipe(map((children) => children.find((ch) => ch.uuid === uuid))) : of(null)).subscribe((child) => {
        this.selectedChildSubject$.next(child);

        // Call lang switch based on "last name" condition
        if (child?.firstName) this.setLangBasedOnChild(child);
      });
    }
  }

  public getSelectedChild() {
    return this.selectedChildSubject$.asObservable();
  }

  public getSelectedChildResults(): Observable<LastResults | null> {
    return this.selectedChildSubject$.pipe(
      distinctUntilChanged(),
      // emit null to reset another child's results and then fetch this child's results
      switchMap((child) => (child ? of(null).pipe(concatWith(this.getLastResults(child.uuid))) : of(null)))
    );
  }

  /**
   * @deprecated Use reactive method `getSelectedChild` instead
   */
  public getSelectedChildValue(): Child {
    return this.selectedChildSubject$.getValue();
  }

  /**
   * A language should be "fixed" after first questionnaire is initiated.
   *
   * @param child A fake child used for storing data - dirty code :)
   */
  private setLangBasedOnChild(child: Child): void {
    const possibleLang = child.lastName;

    if (possibleLang === 'en' || possibleLang === 'lv' || possibleLang === 'tr' || possibleLang === 'cs') {
      this.i18n.setActiveLang(possibleLang);
      this.wasLanguageChosen = true;
      console.log('Setting language to: ' + possibleLang + ' as the questionnaire was initiated so.');
    }
  }
}
