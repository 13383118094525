<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title">{{ t('user/getPremium') }}</h2>
    <button (click)="activeModal.dismiss(null)" aria-label="Close" class="btn-close"><span aria-hidden="true">×</span></button>
  </div>

  <div class="modal-body">
    <img src="assets/img/system-povidla.jpg" [alt]="t('shared/system')" class="d-block m-auto system-povidla" />
  </div>

  <div class="modal-footer">
    <div class="modal-footer-row modal-footer-row-normal">
      <div class="modal-footer-right">
        <button (click)="onActivateCode()" type="button" class="btn btn-secondary btn-lg">{{ t('user/submitActivationCode') }}</button>
        <button (click)="onGoToEshop()" type="button" class="ml-4 btn btn-primary btn-lg">{{ t('shared/goToShop') }}</button>
      </div>
    </div>
  </div>
</ng-container>
