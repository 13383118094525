<div class="dropdown" *transloco="let t">
  <button
    class="btn dropdown-toggle d-inline-flex align-items-center justify-content-center"
    type="button"
    id="dropdown-child-selector-button"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    [disabled]="children?.length === 1"
  >
    <span class="child-selector-selected-name pr-1">
      {{ selectedChild ? (selectedChild.firstName | date : 'dd. MMMM YYYY') : t('shared/selectChild') }}
    </span>
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdown-child-selector-button">
    <a *ngFor="let child of children" [routerLink]="links.result(child)" routerLinkActive="active" class="dropdown-item">
      {{ child.firstName }}
    </a>
  </div>
</div>
