import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { RouterLinkFactory } from '@app/core/router/router-link.factory';
import { AuthService, User } from 'isophi-core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent {
  public loggedUser: User | null = null;

  public constructor(public routerLinkFactory: RouterLinkFactory, private authService: AuthService, private host: ElementRef) {
    this.loggedUser = this.authService.loggedUser;
  }

  onResize() {}
  // @HostListener('window:resize', ['$event'])
  // onResize(rect = document.getElementById('header')?.getBoundingClientRect()) {
  //   this.host.nativeElement.style.height = rect.bottom + 'px';
  // }

  // ngOnInit() {
  //   const observer = new ResizeObserver((entries) => {
  //     this.onResize(entries[0].contentRect);
  //   });
  //   observer.observe(document.getElementById('header'));
  // }

  sidebarClose() {
    // Does this actually work? Cause "sidenav-toggled" seems like closing the sidenav when it is present not removed!
    document.querySelector('.app')?.classList.remove('sidenav-toggled');
  }
}
