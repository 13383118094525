<div class="social">
  <ul class="text-center m-0">
    <li>
      <a href="https://www.facebook.com/isophi.education/" target="_blank" class="social-icon">
        <i class="fa fa-facebook" aria-hidden="true"></i>
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/rozvojditetecz/" target="_blank" class="social-icon">
        <i class="fa fa-instagram" aria-hidden="true"></i>
      </a>
    </li>
    <li>
      <a href="https://www.youtube.com/channel/UCNg151GyAQwRNEQs2BUnUKA" target="_blank" class="social-icon">
        <i class="fa fa-youtube" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
</div>
