import { Component } from '@angular/core';
import { RouterLinkFactory } from '@app/core/router/router-link.factory';
import { AccountService } from '@app/core/services/account.service';
import { ChildService } from '@app/core/services/child.service';
import { HelperService } from '@app/core/services/helper.service';
import { LicenseService } from '@app/core/services/license.service';
import { EditLicenseComponent } from '@app/modules/user/components/edit-license/edit-license.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Child } from '@parents-api';
import { AuthService, User } from 'isophi-core';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  licenses$: Observable<{ premium: boolean }>;

  children$: Observable<Child[]>;

  selectedChild$: Observable<Child>;

  public constructor(
    private childService: ChildService,
    public routerLinkFactory: RouterLinkFactory,
    private modalService: NgbModal,
    private licenseService: LicenseService,
    private accountService: AccountService,
    private authService: AuthService,
    private helperService: HelperService
  ) {
    this.licenses$ = combineLatest({
      premium: this.licenseService.hasPremium()
    });
    this.children$ = this.childService.additionalInfo$.pipe(switchMap(() => this.childService.getChildren()));
    this.selectedChild$ = this.childService.getSelectedChild();
  }

  get username(): string {
    return this.authService.loggedUser?.email;
  }

  get loggedUser(): User {
    return this.authService.loggedUser;
  }

  get title$(): BehaviorSubject<string> {
    return this.helperService.titleChanged$;
  }

  onActivationCode() {
    this.modalService.open(EditLicenseComponent);
  }

  onLogout() {
    this.accountService.logout();
  }

  startQuestionnaire(): void {
    this.helperService.checkAdditionalInfo();
  }
}
