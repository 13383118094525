import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-download-preview',
  templateUrl: './download-preview.component.html',
  styleUrls: ['./download-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadPreviewComponent {
  @Input() url: string;

  @Input() image: string;

  @Input() title: string;

  @Input() locked = false;
}
