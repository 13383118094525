<ng-container *transloco="let t">
  <div class="modal-header align-items-center">
    <h4 class="mb-0 font-weight-bold">{{ t('shared/moreInfoTitle') }}</h4>
    <button (click)="activeModal.dismiss(null)" aria-label="Close" tabindex="-1">
      <img src="assets/img/closeSmallCropped.svg" alt="Zavřít" />
    </button>
  </div>

  <div class="modal-body">
    <p>{{ t('shared/moreInfoDescription') }}</p>
    <hr />
    <div class="more-info-form-container row no-gutters">
      <form class="col" *ngIf="additionalInfo$ | withLoader | async" [formGroup]="moreInfoForm" (ngSubmit)="onSubmit()">
        <div class="row no-gutters">
          <div class="form-group col-md col-12">
            <label for="education">{{ t('registration/education') }} <span class="required-field">*</span></label>
            <ng-select
              id="education"
              formControlName="education"
              [items]="educationLevel"
              bindLabel="text"
              bindValue="value"
              class="form-control"
              [searchable]="false"
              [clearable]="false"
            >
            </ng-select>
          </div>
          <div class="form-group col-md col-12">
            <label for="lengthOfPractice">{{ t('registration/lengthOfPractice') }} <span class="required-field">*</span></label>
            <ng-select
              id="lengthOfPractice"
              formControlName="lengthOfPractice"
              [items]="lengthOfPractices"
              bindLabel="text"
              bindValue="value"
              class="form-control"
              [searchable]="false"
              [clearable]="false"
            >
            </ng-select>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="form-group col-md col-12">
            <label for="region">{{ t('registration/region') }} <span class="required-field">*</span></label>
            <ng-select
              id="region"
              formControlName="region"
              [items]="regions"
              bindLabel="text"
              bindValue="value"
              class="form-control"
              [searchable]="false"
              [clearable]="false"
            >
            </ng-select>
          </div>
          <div class="form-group col-md col-12">
            <label for="gender">{{ t('shared/gender') }} <span class="required-field">*</span></label>

            <div class="form-check">
              <input class="form-check-input" type="radio" id="genderFemale" [value]="GenderEnum.Female" formControlName="extendedGender" />
              <label class="form-check-label label-border-left" for="genderFemale">
                {{ t('shared/female') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="genderMale" [value]="GenderEnum.Male" formControlName="extendedGender" />
              <label class="form-check-label label-border-right" for="genderMale">
                {{ t('shared/male') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="genderOther" [value]="GenderEnum.Other" formControlName="extendedGender" />
              <label class="form-check-label label-border-right" for="genderOther">
                {{ t('shared/other') }}
              </label>
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="form-group col-12">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="contractConsent" formControlName="contractConsent" />
              <label class="form-check-label" for="contractConsent"
                >{{ t('shared/contractConsent') }} <span class="required-field">*</span></label
              >
            </div>
          </div>
        </div>

        <hr />

        <div class="row no-gutters">
          <div class="col text-right">
            <button type="button" (click)="activeModal.dismiss(null)" class="btn btn-light me-4">
              {{ t('shared/cancel') }}
            </button>
            <button class="btn btn-primary">
              {{ t('child/saveChanges') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
