<div class="wrap">
  <div class="page">
    <div class="page-main">
      <app-header></app-header>

      <app-sidebar appHoverEffectSidebar></app-sidebar>

      <div class="app-content main-content">
        <div class="side-app main-container">
          <router-outlet></router-outlet>
        </div>
      </div>

      <app-footer></app-footer>

      <div class="alert alert-warning sticky-footer text-center" role="alert" *ngIf="(licenses$ | async)?.premium === false">
        Využíváte verzi Povidla zdarma
        <a href="https://eshop.isophi.cz/produkt/rodicovska-povidla" target="_blank" class="btn btn-warning ml-2"
          >Pořídit PREMIUM licenci</a
        >
      </div>
    </div>
  </div>
</div>
