import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from 'isophi-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  public constructor(private http: HttpClient, private authService: AuthService) {}

  public patchUser(body: any): Observable<any> {
    const url = `${environment.authAPI}/users/${this.authService.loggedUser.id}/`;

    return this.http.patch(url, body);
  }

  public changePassword(body: any): Observable<any> {
    const url = `${environment.authAPI}/logged-user/change-password/`;

    return this.http.post(url, body);
  }

  public resetPassword(email: string): Observable<any> {
    const url = `${environment.authAPI}/reset-password/`;

    return this.http.post(url, { email });
  }
}
