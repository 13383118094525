<form (ngSubmit)="onSubmit()" *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title">{{ t('user/submitActivationCode') }}</h2>
    <button (click)="activeModal.dismiss(null)" aria-label="Close" class="btn-close"><span aria-hidden="true">×</span></button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label for="activationCode">{{ t('user/enterActivationCode') }}</label>
      <div class="input-group">
        <input
          id="activationCode"
          class="form-control"
          [placeholder]="t('user/activationCode')"
          [(ngModel)]="activationCode"
          name="activationCode"
          required
        />
      </div>
    </div>
    <!-- <button (click)="togglePremium()" class="btn mb-4">DEBUG: Změnit licenci</button> -->
  </div>

  <div class="modal-footer">
    <div class="modal-footer-row">
      <div class="modal-footer-right">
        <button type="submit" class="btn btn-primary btn-lg" [disabled]="!activationCode">{{ t('user/submit') }}</button>
      </div>
    </div>
  </div>
</form>
