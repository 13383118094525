import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {
  constructor(private translocoService: TranslocoService, private toastr: ToastrService) {}

  handleFormError(e, title: string, text: string) {
    if (typeof e.error === 'object' && Object.keys(e.error).length && e.error?.errors.length) {
      e.error?.errors.forEach((errVal) => {
        const camelCaseField = errVal?.field?.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        const errorFieldTranslated = this.translocoService.translate('registration/' + camelCaseField);
        this.toastr.error(`${errorFieldTranslated}: ${errVal.message}`, this.translocoService.translate(title));
      });
    } else if (typeof e.error === 'object' && Object.keys(e.error).length) {
      for (const error in e.error) {
        e.error[error].forEach((errVal: string) => {
          this.toastr.error(errVal, this.translocoService.translate(title));
        });
      }
    } else {
      this.toastr.error(this.translocoService.translate(text), this.translocoService.translate(title));
    }
  }
}
