import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouterLinkFactory } from '@app/core/router/router-link.factory';
import { AuthService, IStorage, LocalStorage } from 'isophi-core';
import { Observable } from 'rxjs';

import { AuthApiService } from './auth-api.service';
import { ChildService } from './child.service';
import { LicenseService } from './license.service';
import { ResultService } from './result.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private storage: IStorage;

  public constructor(
    private authService: AuthService,
    private authApiService: AuthApiService,
    private router: Router,
    private routerLinkFactory: RouterLinkFactory,
    private childService: ChildService,
    private licenseService: LicenseService,
    private resultService: ResultService
  ) {
    this.storage = new LocalStorage();
  }

  /**
   * It will logout user.
   */
  public logout(): void {
    this.performLogout();
    this.router.navigate(this.routerLinkFactory.login());
  }

  /**
   * It will logout user in registration page.
   */
  public logoutRegister(): void {
    this.performLogout();
  }

  public updateUserData(updateUserData: any): Observable<any> {
    return this.authApiService.patchUser(updateUserData);
  }

  public changePassword(body: any): Observable<any> {
    return this.authApiService.changePassword(body);
  }

  public resetPassword(email: string): Observable<any> {
    return this.authApiService.resetPassword(email);
  }

  private performLogout() {
    this.authService.logoutUser(this.storage);
    this.storage.clear();
    this.childService.clear();
    this.licenseService.clear();
    this.resultService.clear();
  }
}
