/* eslint-disable @typescript-eslint/naming-convention */
export const regionData = {
  HL: 'Hlavní město Praha',
  JM: 'Jihomoravský kraj',
  JC: 'Jihočeský kraj',
  KV: 'Karlovarský kraj',
  VY: 'Kraj Vysočina',
  KH: 'Královéhradecký kraj',
  LI: 'Liberecký kraj',
  MS: 'Moravskoslezský kraj',
  OL: 'Olomoucký kraj',
  PA: 'Pardubický kraj',
  PL: 'Plzeňský kraj',
  SC: 'Středočeský kraj',
  US: 'Ústecký kraj',
  ZL: 'Zlínský kraj'
};

export const lengthOfPracticeData = {
  _02: '0-2 let',
  _25: '2-5 let',
  _610: '6-10 let',
  _1115: '11-15 let',
  _16: '16+ let'
};

export const educationLevelData = {
  Primary: 'Základní vzdělání',
  SecodaryWithoutDiploma: 'Středoškolské bez maturity',
  Secodary: 'Středoškolské s maturitou',
  HigherProfessional: 'Vyšší odborné vzdělání',
  University: 'Vysokoškolské vzdělání'
};

export const occupationsData = {
  DIRECTOR: 'Ředitel/ka',
  DEPUTY: 'Zástupce/zástupkyně',
  HEAD_TEACHER: 'Vedoucí učitel/ka',
  TEACHER: 'Učitel/ka',
  ASSISTANT: 'Asistent/ka',
  STUDENT: 'Student/ka'
};
