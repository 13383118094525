import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EditLicenseComponent } from '@app/modules/user/components/edit-license/edit-license.component';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-buy-license',
  templateUrl: './buy-license.component.html',
  styleUrls: ['./buy-license.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BuyLicenseComponent implements OnInit {
  public constructor(public activeModal: NgbActiveModal, private modal: NgbModal) {}

  public ngOnInit(): void {}

  onActivateCode() {
    this.modal.open(EditLicenseComponent);
    this.activeModal.close();
  }

  onGoToEshop() {
    window.open('https://eshop.isophi.cz/produkt/rodicovska-povidla', '_blank');
    this.activeModal.close();
  }
}
