<ng-container *transloco="let t">
  <li class="slide">
    <a class="side-menu__item" [routerLink]="routerLinkFactory.about()" routerLinkActive="active">
      <i class="fe fe-info sidemenu_icon"></i>
      <span class="side-menu__label">{{ t('shared/about') }}</span>
    </a>
  </li>

  <li class="slide">
    <a
      class="side-menu__item"
      [class.disabled]="!hasResults"
      [routerLink]="hasResults ? routerLinkFactory.result(selectedChild) : null"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img class="sidemenu_icon" src="assets/img/iconsDefault/leaderboard.svg" alt="leaderboard" />
      <span class="side-menu__label">{{ t('result/overview') }}</span>
    </a>
  </li>

  <li class="slide">
    <a
      class="side-menu__item"
      [class.disabled]="!hasResults"
      [routerLink]="hasResults ? routerLinkFactory.recommendation(selectedChild) : null"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="fe fe-target sidemenu_icon"></i>
      <span class="side-menu__label">{{ t('shared/supportAreas') }}</span>
    </a>
  </li>

  <li class="slide">
    <a
      class="side-menu__item"
      [class.disabled]="!hasResults"
      [routerLink]="hasResults ? routerLinkFactory.participation() : null"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="fe fe-award sidemenu_icon"></i>
      <span class="side-menu__label">{{ t('info/participation') }}</span>
    </a>
  </li>
</ng-container>
