import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/core/services/helper.service';
import { educationLevelData, lengthOfPracticeData, regionData } from '@app/modules/registration/pages/registration/data';
import { HandleErrorService } from '@app/shared/services/handle-error.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import {
  EducationEnum,
  GenderB21Enum,
  LengthOfPracticeEnum,
  ParentAdditionalInfoService,
  ReadParentAdditionalInformation,
  RegionEnum
} from '@parents-api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-more-info-form',
  templateUrl: './more-info-form.component.html',
  styleUrls: ['./more-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreInfoFormComponent implements OnInit {
  moreInfoForm: FormGroup;

  educationLevel: { [key: string]: string }[] = [];

  lengthOfPractices: { [key: string]: string }[] = [];

  regions: { [key: string]: string }[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  GenderEnum = GenderB21Enum;

  additionalInfo$: Observable<ReadParentAdditionalInformation>;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private parentAdditionalInfoService: ParentAdditionalInfoService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translocoService: TranslocoService,
    private handleErrorService: HandleErrorService
  ) {
    this.educationLevel = this.helperService.prepareData(educationLevelData, EducationEnum);
    // Reorder educationLevel
    [this.educationLevel[1], this.educationLevel[2]] = [this.educationLevel[2], this.educationLevel[1]];
    this.lengthOfPractices = this.helperService.prepareData(lengthOfPracticeData, LengthOfPracticeEnum);
    this.regions = this.helperService.prepareData(regionData, RegionEnum);

    this.moreInfoForm = this.formBuilder.group({
      education: [null, Validators.required],
      lengthOfPractice: [null, Validators.required],
      region: [null, Validators.required],
      extendedGender: ['', Validators.required],
      contractConsent: [false, Validators.required]
    });
  }

  ngOnInit(): void {
    this.additionalInfo$ = this.parentAdditionalInfoService.parentAdditionalInfoGetAdditionalInfoRetrieve().pipe(
      tap((additionalInfo) => {
        if (additionalInfo) {
          this.moreInfoForm.patchValue(additionalInfo);
        }
      })
    );
  }

  onSubmit(): void {
    this.spinner.show();
    this.parentAdditionalInfoService
      .parentAdditionalInfoUpdateAdditionalInfoUpdate(this.moreInfoForm.value)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate('shared/additionalInfoSuccess'));
          this.helperService.addNewFakeChildAndFillQuestionnaire();
          this.activeModal.close();
        },
        error: (e) => {
          this.handleErrorService.handleFormError(e, '', 'shared/additionalInfoError');
        }
      });
  }
}
