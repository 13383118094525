import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ChildService } from '@app/core/services/child.service';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from 'isophi-core';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePickerComponent {
  constructor(public i18n: TranslocoService, public childService: ChildService, public authService: AuthService) {}
}
