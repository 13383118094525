import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LicenseService } from '@app/core/services/license.service';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent {
  licenses$: Observable<{ premium: boolean }>;

  public constructor(private licenseService: LicenseService) {
    this.licenses$ = combineLatest({
      premium: this.licenseService.hasPremium()
    });
  }
}
