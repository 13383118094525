import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CategoriesService } from './api/categories.service';
import { ChildAnnouncementsService } from './api/childAnnouncements.service';
import { ChildCloseRelativeService } from './api/childCloseRelative.service';
import { ChildDayoffsService } from './api/childDayoffs.service';
import { ChildExtendedDataService } from './api/childExtendedData.service';
import { ChildParentAttendanceService } from './api/childParentAttendance.service';
import { ChildProfileService } from './api/childProfile.service';
import { ChildResultsService } from './api/childResults.service';
import { ChildrenService } from './api/children.service';
import { ContractsService } from './api/contracts.service';
import { EduActivitiesService } from './api/eduActivities.service';
import { GeneralService } from './api/general.service';
import { IntrospectService } from './api/introspect.service';
import { MaterialsService } from './api/materials.service';
import { ParentAdditionalInfoService } from './api/parentAdditionalInfo.service';
import { ParentsService } from './api/parents.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CategoriesService,
    ChildAnnouncementsService,
    ChildCloseRelativeService,
    ChildDayoffsService,
    ChildExtendedDataService,
    ChildParentAttendanceService,
    ChildProfileService,
    ChildResultsService,
    ChildrenService,
    ContractsService,
    EduActivitiesService,
    GeneralService,
    IntrospectService,
    MaterialsService,
    ParentAdditionalInfoService,
    ParentsService,
    UsersService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
