import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLinkFactory } from '@app/core/router/router-link.factory';
import { Child } from '@parents-api';

@Component({
  selector: 'app-child-selector',
  templateUrl: './child-selector.component.html',
  styleUrls: ['./child-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChildSelectorComponent {
  @Input() children: Child[];

  @Input() selectedChild: Child;

  public constructor(public links: RouterLinkFactory) {}
}
