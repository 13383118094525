import { Pipe, PipeTransform } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { isObservable, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

/**
 * Run ngx-spinner loader on running Observable, use with async pipe.
 *
 * Example:
 * <ul *ngFor="let item of items$ | withLoader | async">...</ul>
 *
 */
@Pipe({
  name: 'withLoader'
})
export class WithLoaderPipe implements PipeTransform {
  public static loadingCount = 0;

  public constructor(private spinner: NgxSpinnerService) {}

  public transform<T>(value: Observable<T>): Observable<T>;
  public transform<T>(value: T): T;

  public transform<T>(value: T | Observable<T>): T | Observable<T> {
    if (!isObservable(value)) return value;

    WithLoaderPipe.loadingCount++;
    this.spinner.show();
    return value.pipe(
      tap(() => {
        if (WithLoaderPipe.loadingCount === 0 || --WithLoaderPipe.loadingCount === 0) {
          this.spinner.hide();
        }
      }),
      catchError((err) => {
        this.spinner.hide();
        return throwError(() => err);
      })
    );
  }
}
