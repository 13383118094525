import { Injectable } from '@angular/core';
import { ChildResult, ChildResultsService, EvaluationRule } from '@parents-api';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { CategoryResultGroup } from '../models/category-result-group.model';
import { ResultType } from '../models/result-type.model';
import { CategoryService } from './category.service';
import { ChildService } from './child.service';

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  public selectedResultType$: Observable<ResultType>;

  public selectedResult$: Observable<ChildResult>;

  private selectedResultTypeSubject$ = new BehaviorSubject<ResultType>('questionnaire');

  public constructor(
    private childResultsApiService: ChildResultsService,
    private categoryService: CategoryService,
    private childService: ChildService
  ) {
    this.selectedResultType$ = this.selectedResultTypeSubject$.asObservable();

    this.selectedResult$ = combineLatest([childService.getSelectedChildResults(), this.selectedResultType$]).pipe(
      tap(this.setToAvailableResultType),
      map(([results, type]) => results?.[type])
    );
  }

  public setSelectedResultType(resultType: ResultType) {
    this.selectedResultTypeSubject$.next(resultType);
  }

  public getSelectedResultType(): Observable<ResultType> {
    return this.selectedResultType$;
  }

  public getSelectedResult(): Observable<ChildResult> {
    return this.selectedResult$;
  }

  public getSelectedResultCategoryGroups(): Observable<{ [evaluationCode: string]: CategoryResultGroup } | null> {
    return combineLatest([this.getSelectedResult(), this.categoryService.getCategoryMap()]).pipe(
      map(([result, categories]) => {
        if (!(result && categories)) {
          return null;
        }

        return result.categoryResults.reduce(
          (groups, { categoryUuid, evaluationCode, evaluationBackgroundColor }) => ({
            ...groups,
            [evaluationCode]: {
              evaluationCode,
              evaluationBackgroundColor,
              categories: [...(groups[evaluationCode]?.categories || []), categories[categoryUuid]]
            }
          }),
          {}
        );
      })
    );
  }

  public getQuestionnaireCategoriesEvaluationCodes(): Observable<{ [uuid: string]: number } | null> {
    return this.childService.getSelectedChildResults().pipe(
      map((lastResults) =>
        lastResults?.questionnaire
          ? lastResults.questionnaire.categoryResults.reduce(
              (categoryEvaluationCodes, categoryResult) => ({
                ...categoryEvaluationCodes,
                [categoryResult.categoryUuid]: categoryResult.evaluationCode
              }),
              {}
            )
          : null
      )
    );
  }

  public getGeneralEvaluationRule(uuid: string): Observable<EvaluationRule> {
    return this.childResultsApiService.getGeneralEvaluationRule(uuid);
  }

  public getResultEvaluationRule(): Observable<EvaluationRule | null> {
    return this.selectedResult$.pipe(
      switchMap((result) => (result ? this.getGeneralEvaluationRule(result.dataSetUuid) : of(null))),
      tap((evaluationRule) => evaluationRule?.ranges?.sort((a, b) => b.code - a.code))
    );
  }

  public clear() {
    this.selectedResultTypeSubject$.next('questionnaire');
  }

  private setToAvailableResultType = ([results, type]) => {
    if (!results?.[type]) {
      if (results?.dadTest) {
        this.setSelectedResultType('dadTest');
      } else if (results?.questionnaire) {
        this.setSelectedResultType('questionnaire');
      }
    }
  };
}
