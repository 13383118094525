/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EducationEnum } from './educationEnum';
import { GenderB21Enum } from './genderB21Enum';
import { LengthOfPracticeEnum } from './lengthOfPracticeEnum';
import { OccupationEnum } from './occupationEnum';
import { PreferredLanguageEnum } from './preferredLanguageEnum';
import { RegionEnum } from './regionEnum';

/**
 * Register new parent data.
 */
export interface RegisterParent { 
    firstName?: string;
    lastName?: string;
    email: string;
    password: string;
    generalTipsVersion?: string;
    generalTipsConsent?: boolean;
    contractKeyword?: string;
    contractVersion?: string;
    contractConsent: boolean;
    captchaToken?: string;
    activationCode?: string;
    preferredLanguage?: PreferredLanguageEnum;
    lengthOfPractice?: LengthOfPracticeEnum;
    region?: RegionEnum;
    education?: EducationEnum;
    occupation?: OccupationEnum;
    nickname?: string;
    gender?: GenderB21Enum;
}