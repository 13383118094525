<ng-container *transloco="let t">
  <ng-container
    *ngIf="{
      children: children$ | async,
      licenses: licenses$ | async
    } as vm"
  >
    <ul class="side-menu">
      <app-navigation
        class="ml-3"
        [selectedChild]="selectedChild$ | async"
        [results]="results$ | async"
        [children]="vm.children"
      ></app-navigation>
    </ul>

    <ul *ngIf="isTeacher" class="side-menu mb-3">
      <li>
        <a
          class="btn btn-outline-primary btn-sm mng-btn mb-3"
          [href]="mngAppUrl"
          target="management"
          (mouseover)="changeImage('assets/img/logo/logo.png', externalApps.MANAGEMENT)"
          (mouseleave)="changeImage('assets/img/logo/isophi-mng_wheel_logo_256.png', externalApps.MANAGEMENT)"
        >
          <img class="sidemenu_icon" [src]="imageSrcManagement" alt="Management logo" />
          <span class="fw-bold side-menu__label">{{ t('shared/managementApp') }}</span>
        </a>
      </li>
      <li>
        <a
          class="btn btn-outline-primary btn-sm mb-3"
          [href]="teacherAppUrl"
          target="diagnostika"
          (mouseover)="changeImage('assets/img/logo/logo.png', externalApps.TEACHER)"
          (mouseleave)="changeImage('assets/img/logo/isophi-diag_wheel_logo_256.png', externalApps.TEACHER)"
        >
          <img class="sidemenu_icon" [src]="imageSrcTeacher" alt="Teacher logo" />
          <span class="fw-bold side-menu__label">{{ t('shared/diagnostics') }}</span>
        </a>
      </li>
    </ul>
  </ng-container>
</ng-container>
