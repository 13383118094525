<ng-container *transloco="let t">
  <div class="app-sidebar__overlay" (click)="sidebarClose()"></div>
  <div class="sticky">
    <aside class="app-sidebar">
      <div class="app-sidebar__logo">
        <a class="header-brand" [routerLink]="routerLinkFactory.homepage()">
          <img src="assets/img/logo/iSophi_podpora_pedagoga.svg" class="header-brand-img desktop-lgo" [alt]="t('shared/iSophiR')" />
          <img src="assets/img/logo/iSophi_podpora_pedagoga.svg" class="header-brand-img dark-logo" [alt]="t('shared/iSophiR')" />
          <img src="assets/img/logo/favicon.png" class="header-brand-img mobile-logo" [alt]="t('shared/iSophiR')" />
          <img src="assets/img/logo/favicon.png" class="header-brand-img darkmobile-logo" [alt]="t('shared/iSophiR')" />
        </a>
      </div>
      <div class="app-sidebar3">
        <div class="main-menu">
          <app-menu *ngIf="loggedUser" (resized)="onResize()"></app-menu>
        </div>
      </div>
    </aside>
  </div>
</ng-container>
