import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService, IsophiCoreService, IStorage, LicenseService, LocalStorage } from 'isophi-core';
import { catchError, from, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  protected storage: IStorage;

  public constructor(
    private isophiCoreService: IsophiCoreService,
    private authService: AuthService,
    private licenseService: LicenseService
  ) {
    this.storage = new LocalStorage();
  }

  /**
   * Init application.
   */
  public initApplication(): Observable<void> {
    // init isophi-core library
    this.isophiCoreService.setUpRequiredFields(
      environment.common.version,
      environment.common.appKeyword,
      environment.client_id,
      environment.parentApiBase + '/api/v1'
    );
    this.isophiCoreService.setField('auth-api', environment.authAPI);
    this.isophiCoreService.setField('auth-extra-models', 'pap-parent,teacher');
    this.isophiCoreService.setField('license-api', environment.licenseAPI);

    // restore access token and try to load user
    this.authService.restoreToken(this.storage);
    return from(this.authService.authenticateByAccessToken()).pipe(
      tap(() => (this.licenseService.licenseSubjectUuid = this.authService.loggedUser.uuid)),
      catchError(() => of(null))
    );
  }
}
